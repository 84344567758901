import LongForm from "../Components/LongFormContent";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import '../Styles/Invitation.css';


function mcCodeSubmit() {
    const codeValue = document.getElementById('mcCode').value;
    if (codeValue === "" || codeValue.length !== 6 || codeValue.match(/^[0-9A-Za-z]+$/) === null) {
        alert("TEST");
        alert.show('The code you entered is either invalid or has expired');
    }else{
        //it is alphanumeric
        window.location.href = "https://www.fusioncraft.org/mc/" + codeValue;
    }
}
export default function RedeemMinecraft() {
    const {code} = useParams()
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);


    useEffect(() => {
        if (code === undefined) {
            // Normal Redeem code link thing
            setError(null);
            setData(null);
            setLoading(false);
            document.getElementById('CodeNotFound').style.display = 'block';
        } else {
            fetch('https://auth.fusioncraft.org/link/minecraft/' + code)
                .then((response) => {
                    return response.json()
                })
                .then((actualData) => {
                    let serverResponse;
                    serverResponse = JSON.parse(actualData.Data)
                    if (actualData.Error === true) {
                        throw new Error(actualData.Message)
                    } else {

                        //
                        // Data we have is real!
                        setData({
                            link: serverResponse.links.discord
                        })
                        let mcCode = document.getElementById('mcCode');
                        mcCode.value = code;
                        mcCode.readOnly = true;

                        document.getElementById('mcSubmitBtn').style.display = 'none';
                        document.getElementById('CodeFound').style.display = 'block';
                        document.getElementById('CodeNotFound').style.display = 'none';
                    }

                })
                .catch((err) => {
                    setError(err.message);
                    setData(null);
                    document.getElementById('CodeNotFound').style.display = 'block';
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [code]);

    return LongForm('redeem-mc-icon', "Minecraft Linking",
        <div className="container">
            <div className="row mcRedemption">
                <div className="col-lg-12 m-1">
                    {loading && <div>A moment please...</div>}
                    {error && (
                        <div className="alert alert-danger">
                            <strong>Error! </strong> {error}
                        </div>
                    )}
                    <div id="CodeNotFound">
                        <div className="col-lg-12 m-auto">
                            <div className="row">
                                <div className="col-md-8 m-auto">
                                    <h2 className="p-0">Hello!</h2>
                                    <p>Attempting to link your Minecraft account to whitelist yourself on the server? Enter the code provided on screen while connecting, and follow the linking process below to continue.</p>
                                    <br />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="row">
                            <div className="col-md-1">
                                <img src="/assets/fuzi-mc-invite.png" alt="Fuzi Minecrafting" />
                            </div>
                            <div className="col-md-3 mc-form text-center">
                                <h5>Enter Link Code Below:</h5>
                                <input type="text" className="form-control mcField" id="mcCode" placeholder="012A3FH" maxLength="6"/>
                                <button  onClick={mcCodeSubmit} className="btn btn-sm btn-primary" id="mcSubmitBtn" >Continue »</button>
                            </div>
                        </div>
                    </div>
                    <div id="CodeFound">
                        <div className="col-lg-9 m-auto">
                            <h2 className="p-0">I found your code!</h2>
                            <p>Below are the social media options available to complete the linking process. Click one of the links shown below, follow the process to link your account, and start gaming!</p>
                            <br />
                        </div>

                        {/* Discord Introduction for Whitelisting */}
                        <div className="col-md-5 m-auto text-center">
                            <h6 className="text-capitalize border-bottom">Social Media Login Options</h6>
                            <a href={data && data.link} className="noUnderline">
                                <div className="OAuthProvider DiscordOauth">
                                    <div className="oAuth-Action">Link Discord »</div>
                                </div>
                            </a>
                            <br />
                        </div>

                        <div className="col-lg-10 m-auto RedemptionFooter text-center border-top">
                            <div className="text-gray-300 sm:text-left text-center"> <br /><strong>Note:</strong> Discord is currently the only Social Media Platform allowed for FusionCraft registration. </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}