import LongForm from "../Components/LongFormContent";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import '../Styles/Invitation.css';

export default function RedeemContentCreator() {
    const {platform} = useParams()
    const {code} = useParams()
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    let ErrorMessage =        "Please check the link you were given and try again. If you are stilling having issues reach out for support on our Discord.";

    useEffect(() => {
        fetch('https://auth.fusioncraft.org/content/' + code)
            .then((response) => {
                return response.json()
            })
            .then((actualData) => {
                console.log(actualData)
                let serverResponse;
                serverResponse = JSON.parse(actualData.Data)
                if (actualData.Error === true) {
                    setData(null)
                    document.getElementById('creatorRedemption').style.display = 'none';
                    throw new Error(actualData.Message + " " + ErrorMessage);

                } else {
                    if(platform === 'youtube') {
                        if(serverResponse.links.youtube === undefined) {
                            throw new Error("Invalid Link Code! " + ErrorMessage);
                        }
                        //
                        // Data we have is real!
                        setData({
                            link: serverResponse.links.youtube
                        })
                        document.getElementById('TwitchOAuthComponent').style.display = 'none';
                    } else {
                        if(serverResponse.links.twitch === undefined) {
                            throw new Error("Invalid Link Code! " + ErrorMessage);
                        }

                        //
                        // Data we have is real!
                        setData({
                            link: serverResponse.links.twitch
                        })
                        document.getElementById('YoutubeOAuthComponent').style.display = 'none';
                    }
                    let mcCode = document.getElementById('mcCode');
                    mcCode.value = code;
                    mcCode.readOnly = true;

                    document.getElementById('CodeFound').style.display = 'block';
                }

            })
            .catch((err) => {
                setError(err.message);
                setData(null);
                document.getElementById('creatorRedemption').style.display = 'none';
            })
            .finally(() => {
                setLoading(false);
            });
    }, [code]);

    return LongForm('redeem-creator-icon', platform.charAt(0).toUpperCase() + platform.slice(1) + " Linking",
        <div className="container">
            <input type="hidden" id="creatorPlatform" />
            <div className="col-lg-8 m-auto">
                {loading && <div>A moment please...</div>}
                {error && (
                    <div className="alert alert-danger">
                        <strong>Error! </strong> {error}
                    </div>
                )}
            </div>
            <div className="row creatorRedemption" id="creatorRedemption">
                    <div className="col-lg-12">
                        <div className="row">
                            <div className="col-md-1">
                                <img src="/assets/fuzi-creator-invite.png" alt="Fuzi Creator" />
                            </div>
                            <div className="col-md-3 mc-form text-center">
                                <h5>Your Link Code</h5>
                                <input type="text" className="form-control mcField" id="mcCode" placeholder="012A3FH" />
                            </div>
                        </div>
                    </div>
                    <div id="CodeFound">
                        <div className="col-lg-9 m-auto">
                            <h2 className="p-0">I found you!</h2>
                            <p>Follow the link below to complete your account verification. Once the account ownership is verified your new content will be published to the Discord content channel.</p>

                        </div>

                        {/* Discord Introduction for Whitelisting */}
                        <div className="col-md-5 m-auto text-center">
                            {data && <TwitchOAuth link={data.link}/> }
                            <br />
                            {data && <YouTubeOAuth link={data.link}/> }
                            <br />
                        </div>
                    </div>
                </div>
            </div>
    )
}

function YouTubeOAuth(data) {
    return (
        <a href={data.link} className="noUnderline" id="YoutubeOAuthComponent">
            <div className="OAuthProvider YoutubeOauth">
                <br />
                <div className="oAuth-Action">Link YouTube »</div>
                <br />
            </div>
        </a>
    );
}

function TwitchOAuth(data) {
    return (
        <a href={data.link} className="noUnderline" id="TwitchOAuthComponent">
            <div className="OAuthProvider TwitchOauth">
                <br />
                <div className="oAuth-Action">Link Twitch »</div>
                <br />
            </div>
        </a>
    );
}