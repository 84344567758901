import LongForm from "../Components/LongFormContent";
import Faq from "react-faq-component";

function FAQ() {

    const data = {
        title: "FusionCraft - Frequently Asked Questions",
        rows: [
            {
                title: "Why is FusionCraft 18+?",
                content: "When we started FusionCraft we collectively decided that we didn't want to heavily moderate, or censor chat. As a result topics can become adult in nature and not suitable for some. Keeping the player base over 18 means we can be more relaxed about what we all say via chat."
            },
            {
                title: "Why is FusionCraft invite only?",
                content: "This community was started by a group of people who already played Minecraft together. So we want to keep that community feeling that we started with. Adding only people invited by existing members allows for the community to grow while also keeping the positive and friendly atmosphere that we all value so much."
            },
            {
                title: "How do I invite someone?",
                content: "Easy! Find the discord bot 'FusionBot' and run the /invite command. Provide the link to your friend and he can complete the sign up process. Anyone you invite <strong>MUST</strong> be 18+."
            },
            {
                title: "How many people can I invite? How soon am I allowed to invite others?",
                content: "You can invite 5 people within a 30 day period (does not reset with a calendar month). You will be able to invite friends after 14 days from joining the community. "
            },
            {
                title: "What happens if a person I invite is banned?",
                content: "A member will never be banned for the actions of another individual. However if a pattern emerges the Community Leaders reserve the right to revoke your ability to invite new members."
            },
            {
                title: "Can I post invites on public forums/social media?",
                content: "No. Doing so can result in your loss of ability to invite friends in the future."
            },
            {
                title: "Do I need to know the people I am inviting?",
                content: "You do not need to personally know those you are inviting. But you should at least have played games with them (such as Minecraft) and ensure they are people you would want to play games with again in the future."
            },
            {
                title: "What mod(s)/client(s) do you allow on your MineCraft servers?",
                content: "Any kind of hacked client (like Future) is not allowed at all. Also, do not use any method of X-Ray-ing. Other than that we do not have a specific list of banned mods, but if you aren't sure you can reach out to one of the Community Leaders to discuss it."
            },
            {
                title: "Do I need a second account for a 'Camera Account?'",
                content: "Yes you will need to have a second licensed copy of Minecraft. Afterwards you can register the IGN within the discord server and the account will only be available in spectator mode on the survival server."
            },
            {
                title: "I am a content creator, am I allowed to promote my channels in FusionCraft? Am I allowed to stream/record on the server(s)?",
                content: "Yes! You can register your channels within the discord and members can be notified when you post new content/go live. If you are streaming on the platform we ask you to give others a warning before you 'go live' so they are aware."
            },
            {
                title: "I have an issue with another member?",
                content: "We hope that our members are able to work issues out among themselves whenever possible. Calm and respectful communication can usually solve any conflict. However, please reach out to a Community Leader if you feel you need assistance/moderation of a situation or dispute."
            },
            {
                title: "How can I support/donate to FusionCraft?",
                content: "Currently we are not taking donations. There will be a time, in the future, when we will. I want to have a complete solution built before accepting any donation to support it."
            }
        ],
    };

    const styles = {
        // bgColor: 'white',
        titleTextColor: "#1a1a1a",
        rowTitleColor: "#615dfa",
        rowContentColor: '#1a1a1a',
        // arrowColor: "red",
    };

    const config = {
        // animate: true,
        // arrowIcon: "V",
        // tabFocus: true
    };

    return LongForm('faq-home-icon', 'FAQ',
        <div className="container">
            <div className="row">
                <div className="col-lg-12 m-1">
                    <Faq
                        data={data}
                        styles={styles}
                        config={config}
                    />
                </div>
            </div>
        </div>
    );
}
export default FAQ;

